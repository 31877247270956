.header {
  width: 207px;
  height: auto;
}

.searchArtist {
  flex: 15%;
  width: 90%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.searchBar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem .2rem 1rem;
  width: 100%;
  background-color: #4D4D4D;
  padding: .7rem .5rem;
}

.showingResultsContainer {
  display: flex;
  min-height: 80px;
  max-width: 90%;
  align-items: flex-end;
}

.showingResults {
  margin-left: 15px;
  color: #FFFFFF;
  opacity: 0.8;
  font-size: 17px;
}

.serchImage {
  margin-left: 15px;
  width: 32px;
  height: 32px;
}

.searchBar > input {
  width: 90%;
  background-color: #4D4D4D;
  border: 0;
  padding-left: 15px;
  color: #FFFFFF;
  font-family: Roboto;
  opacity: 0.8;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.45px;
  line-height: 28px;
  outline: none;
}

.totalArtists {
  color: #4D4D4D;
  align-self: flex-end;
}

.listArtistContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 80px;
}

.artistContainer {
  display: flex;
  position: relative;
  justify-content: flex-start;
  align-items: flex-end;
  width: 225px;
  height: 225px;
  margin: .3rem;
  border: solid 1px #FFFFFF;
}

.artistContainer:hover{
  cursor: pointer;
  opacity: .8;
 }

.artistName {
  margin: 15px;
  color: #FFFFFF;
  font-family: Roboto;
  font-weight: bold;
  font-size: 20px;
  z-index: 2;
  text-transform: capitalize;
}

.artistLogo {
  opacity: 0.6;
  position: absolute;
  width: 225px;
  height: 225px;
}

.notFoundArtistAlertContainer{
  margin-top: 170px;
}

.notFoundArtistAlert {
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 40px;
}

.notFoundArtistAlertTitle {
  font-weight: bold;
}

@media (max-width: 500px) {

  .searchArtistComponentcontainer{
    align-items: center;
  }

  .searchArtist {
    align-items: center;
  }

  .searchBar {
    width: 92%;
    padding: .7rem 1rem;
  }

  .searchBar > input {
    font-size: 14px;
    padding-left: 10px;
  }

  .logoRed, header {
    margin: 2rem 0rem 0rem 0rem;
    width: 150px;
    height: auto;
  }

  .serchImage {
    width: 24px;
    height: 24px;
  }

  .artistContainer {
    width: 150px;
    height: 150px;
  }

  .artistName {
    font-size: 16px;
  }

  .artistLogo {
    width: 150px;
    height: 150px;
  }

  .showingResultsContainer {
    min-height: 60px;
  }

  .showingResults {
    font-size: 14px;
  }

  .notFoundArtistAlertContainer{
    margin-top: 40px;
  }

  .notFoundArtistAlert {
    color: #FFFFFF;
    font-family: Roboto;
    font-size: 20px;
  }

  .notFoundArtistAlertTitle {
    font-weight: bold;
  }

}
