.helpUsContainer {
  background: linear-gradient(
		rgba(0, 0, 0, 0.5),
		rgba(0, 0, 0, 0.5)
	), url("../../static/images/background.png") no-repeat center top;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	overflow-x: hidden;
}

.helpUsContent {
  padding-left: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  min-width: 50vw;
}

.donationLinks {
  display: flex;
  justify-content: center;
  margin-top: 6rem;
}

.paypalLink, .patreonLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.aboutUsFooterLink {
  cursor: pointer;
  margin: 1rem 2rem;
  opacity: 0.8;
  color: #fff;
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.68px;
  line-height: 28px;
  text-decoration: none;
}

.paypalLink > svg:hover {
  color: rgba(202, 194, 194, 0.781);
}

.patreonLink > svg:hover {
  color: rgba(202, 194, 194, 0.781);
}

@media (max-width: 500px) {

  .helpUsContainer {
    justify-content: space-around;
  }

  .footerLink {
    font-size: 16px;
  }
}
