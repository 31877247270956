
.formContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.form {
  padding-top: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  max-width: 900px;
  height: auto;
}

.headerContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 40px;
}

.headerSubTitle{
  font-size: 35px;
  font-weight: 300;
  font-family: Roboto;
  color: #FFFFFF;
  text-align: center;
}

.headerTitle{
  font-size: 120px;
  font-weight: 300;
  font-family: Montserrat Regular;
  font-weight: bold;
  color: #FFFFFF;
  text-transform: capitalize;
  text-align: center;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
}

.inputTitle {
  width: 100%;
  max-width: 620px;
  font-size: 35px;
  font-family: Montserrat Regular;
  color: #FFFFFF;
  margin-bottom: 5px;
}

.input {
  background-color: rgba(255,255,255, 0.3);
  color: #FFFFFF;
  padding: 12px 10px;
  width: 100%;
  max-width: 600px;
  font-size: 20px;
  margin-bottom: 30px;
  border-color: transparent;
}

.input::placeholder {
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.68px;
}

.buttonContainer {
  margin: 0 0 140px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.termsAndConditions {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  font-size: 20px;
  width: 60%;
  justify-content: space-around;
}

.termsAndConditions > span > a {
  text-decoration: underline;
  color: white;
  font-weight: 600;
}

.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
  color: #FFFFFF;
  font-size: 20px;
}

.checkbox > span {
  color: #FFFFFF;
  padding: 0.5rem 0.25rem;
}

.checkbox > input {
  height: 25px;
  width: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #FFFFFF;
  transition-duration: 0.3s;
  background-color: #FFFFFF;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid #34495E;
  background-color: #FFFFFF;
}

.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  font-size: x-large;
  text-align: center;
  font-weight: 900;
  color: #34495E;
  position: absolute;
  left: 0.5rem;
  top: 0.2rem;
}

.checkbox > input:active {
  border: 2px solid #FFFFFF;
}

/* If the app is loading */

.isFetching {
  display: flex;
  position: fixed;
  opacity: 0.9;
  background-color: black;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imgLoading {
  margin-bottom: 10px;
  height: 115px;
  width: 100px;
}

.textLoading {
  color: #FFFFFF;
  font-family: Montserrat Regular;
  font-size: 48px;
  letter-spacing: 0.9px;
  line-height: 58px;
}

.errorInput {
  background-color: rgba(255,255,255, 0.3);
  color: #FFFFFF;
  padding: 12px 10px;
  width: 100%;
  max-width: 600px;
  font-size: 20px;
  margin-bottom: 30px;
  border-color: #d61e0e;
}

.errorMsg {
  color: #d61e0e;
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 500px) {

  .SeedDataContainer{
    align-items: center;
  }

  .form {
    padding: 0px;
  }

  .headerContainer {
    padding-bottom: 20px;
    margin: 2rem 0;
  }

  .headerSubTitle {
    margin: 0px;
    font-size: 20px;
  }

  .headerTitle {
    font-size: 60px;
  }

  .inputTitle {
    font-size: 22px;
  }

  .input::placeholder {
    font-size: 16px;
  }

  .termsAndConditions {
    width: 80%;
  }

  .buttonContainer {
    margin: 0px 0px 15px;
  }

  .textLoading {
    font-size: 25px;
  }

}
