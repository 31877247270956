.logoRed {
  margin: 50px 40px 0;
  width: 207px;
  height: 111px;
}

.buttonsContainer {
  padding: 40px;
  vertical-align: center;
}

.homeContent {
  padding-left: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: #FFFFFF;
  min-width: 50vw;
}

.homeContent > h2 {
	font-size: 30px;
  font-weight: 400;
  width: 60%;
}

.tryButton {
  outline: none;
  border: none;
  color: #fff;
  margin: 2rem 0;
  font-size: 20px;
  background-color: #E32C3E;
  padding: 1.5rem 4rem;
  text-decoration: none;
}

.tryButton:hover {
  background-color: #800;
}

.mainFooter {
  color: #FFFFFF;
  font-family: 'Roboto';
  font-size: 24px;
}

.homeLinks {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  align-items: center;
  width: 100%;
}

.mainLink {
  cursor: pointer;
  margin: 0 2rem;
  opacity: 0.8;
  color: #fff;
  font-family: 'Roboto';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.68px;
  line-height: 28px;
  text-decoration: none;
}

.githubLink {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.termsConditionsLink, .sanNicolasLink {
  color: #FFFFFF;
  text-decoration: underline;
}

@media (max-width: 500px) {

  .container{
    align-items: center;
  }

  .tryButton {
    margin: 10px 0;
    font-size: 15px;
    padding: 1rem 2.5rem;
  }

  .homeContent > h2 {
    font-size: 16px;
    font-weight: 400;
  }
  
  .homeContent > p {
    font-size: 12px;
    text-align: left;
    line-height: normal;
  }

  .homeLinks {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 1rem 0;
  }

  .mainLink {
    font-size: 16px;
    margin: 0 .2rem;
    min-width: 70px;
    text-align: center;
  }

  .githubLink {
    min-width: 100px !important;
  }

  .githubLink > svg {
    width: 2rem !important;
  }

}
