.generatedSongContainer {
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.generatedSongHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bandNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 40px 0;
}


.bandNameTitle {
  font-size: 30px;
  letter-spacing: 0.68px;
  line-height: 44px;
  text-transform: capitalize;
  font-weight: 300;
  font-family: Montserrat Regular;
  font-weight: bold;
  color: #FFFFFF;
  text-transform: capitalize;
  text-align: center;
}

.montserratTextBold {
  font-weight: bold;
  font-size: 45px;
}

.textSongFlexContainer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.textSongContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.textSongTitle {
  color: #FFFFFF;
  font-family: Mattilda;
  font-size: 55px;
  text-align: center;
  letter-spacing: 1.2px;
  line-height: 78px;
}

.textSong {
  white-space: pre-wrap;
  color: #FFFFFF;
  font-family: Montserrat Regular;
  font-size: 25px;
  letter-spacing: 0.56px;
  line-height: 37px;
  text-align: center;
}

.generatedSongFooterContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
}

.generatedSongFooterContainer span {
  font-family: Montserrat Regular;
  font-weight: bold;
  margin-bottom: 30px;
}

.shareTitle {
  font-size: 48px;
  color: #DB4344;
}

.socialContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  margin-bottom: 50px;
}

.link {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.link > svg:hover {
  color: rgba(202, 194, 194, 0.781);
}

.tryItText {
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
}

.oneMoreTime {
  margin-top: .5rem;
}


@media (max-width: 500px) {

  .generatedSongHeader {
    flex-direction: column;
  }

  .textSongTitle {
    font-size: 30px;
    letter-spacing: 0;
  }

  .textSong {
    font-size: 15px;
  }

  .montserratTextBold {
    font-size: 60px;
  }

  .oneMoreTime {
    margin-top: .5rem;

  }

}
