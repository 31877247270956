.aboutUsContainer {
  background: #000000;
  overflow-x: hidden;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100vh;
}

.hamburger {
  margin-top: 100px;
  height: 200px;
  background: blue;
  box-sizing: border-box;
  overflow: auto;
}

.aboutUsHeader {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 180px;
  background: #000000;
}

.aboutUsLogoContainer {
  height: 100%;
  position: relative;
  display: flex;
  margin: 40px 40px 0;
  flex-direction: column;
  text-decoration: none;
}

.aboutUsLogoRed {
  position: absolute;
  width: 207px;
  height: 111px;
  margin-left: 5px;
  margin-top: 30px;
}

.aboutUsTitleImage {
  color: #FFFFFF;
  font-family: Montserrat Regular;
  font-size: 45px;
  font-weight: bold;
  letter-spacing: 0.9px;
  line-height: 58px;
  width: 350px;
}

.aboutUsMenuContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 35px;
  height: 40%;
}

.aboutUsMenu {
  width: -300px;
}

.menuButton {
  display: none;
  cursor: pointer;
}

.aboutUsMenuFullScreen {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 35px 15px 0 0;
  height: 40%;
}

.menu {
  width: 32px;
  height: 32px;
}

.aboutUsMenuTitle {
  margin-right: 30px;
  color: #FFFFFF;
  font-family: Montserrat Regular;
  font-size: 17px;
  letter-spacing: 0.38px;
  line-height: 24px;
  cursor: pointer;
}

.aboutUsSectionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 320px;
  width: 100%;
}

.aboutUsSecondSectionDataContainer {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.aboutUsSectionSeparatorContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.aboutUsSectionSeparator {
  flex: 1;
  margin: 0 30px;
  background-color: #FFFFFF;
  height: 1px;
}

.aboutUsSectionTitleContainer {
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 60px;
}

.aboutUsSectionTitle {
  color: #FFFFFF;
  font-family: Montserrat Regular;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.68px;
  line-height: 44px;
  width: 100%;
  text-align: center;
}

.aboutUsSectionDataContainer {
  margin-top: 0 60px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
}

@media (max-width: 1200px) {

  .aboutUsSectionDataContainer {
    margin-top: 60px;
    align-items: center;
    flex-direction: column;
    margin-bottom: 60px;
  }

  .menuButton {
    display: flex;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 20px;
    margin-left: 20px;
    cursor: pointer;
  }

  .aboutUsMenu {
    background-color: black;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 30px 0 0 30px;
    position: absolute;
    width: 300px;
    top: 0;
    left: 0;
    height: auto;
    margin-top: 35px;
  }

  .aboutUsMenuFullScreen {
    display: none;
  }

  .aboutUsContainer {
    align-items: center;
  }

  .aboutUsLogoContainer {
    width: 100%;
    align-items: center;
    margin: 60px 0 0px;
  }

  .aboutUsLogoRed {
    margin: 2rem 0rem 0rem 0rem;
    width: 150px;
    height: auto;
  }

  .aboutUsTitleImage {
    font-size: 30px;
    text-align: center;
  }

  .aboutUsSectionContainer {
    padding-top: 250px;
  }

  .aboutUsSectionTitle {
    font-size: 18px;
  }

  .aboutUsSectionSeparator {
    margin: 0 5px;
  }

  .aboutUsSectionTitleContainer {
    margin: 0 10px;
  }

}
