@font-face {
  font-family: 'Montserrat Regular';
  src: local('Montserrat Regular'), url('./static/fonts/Montserrat/Montserrat-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat Regular';
  src: local('Montserrat Regular'), url('./static/fonts/Montserrat/Montserrat-ExtraBold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./static/fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./static/fonts/Roboto/Roboto-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'Mattilda';
  src: local('Mattilda'), url('./static/fonts/Mattilda/Mattilda.otf');
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: black;
  font-family: 'Montserrat Regular';
}

.containerLayout {
	width: 100%;
	min-height: 100vh;
  padding-left: 0;
  color: #FFFFFF;
  min-width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.regularContainerImage {
  background: linear-gradient(
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0.5)
  ), url("./static/images/background.png") no-repeat center top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow-x: hidden;
}

.regularHeader {
  align-self: flex-start;
}

.logoRed {
  margin: 50px 40px 0;
  width: 207px;
  height: 111px;
}

.pageTitle {
	font-size: 45px;
  font-weight: 400;
  width: 85%;
}

.pageText {
  text-align: justify;
  line-height: normal;
  font-size: 24px;
  width: 60%;
}


@media (max-width: 500px) {
  
  .regularHeader {
    align-self: center;
  }

  .logoRed {
    margin: 2rem 0rem 0rem 0rem;
    width: 150px;
    height: auto;
  }

  .pageTitle {
    font-size: 22px;
    letter-spacing: 0.9px;
    line-height: 29px;
    width: 90%;
    margin: 1rem 0 2rem;
    font-weight: 400;
  }

  .pageText {
    font-size: 12px;
    text-align: justify;
    width: 80%;
    line-height: normal;
  }

}
