.aboutUsSectionData {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 540px;
  margin: 0 100px;
}

.aboutUsSectionImage {
  border-radius: 100px;
  height: 200px;
  width: 200px;
}

.aboutUsSectionName {
  margin-top: 20px;
  color: #FFFFFF;
  font-family: Montserrat Regular;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.68px;
  line-height: 44px;
  width: 100%;
  text-align: center;
}

.aboutUsSectionRole {
  margin-top: 20px;
  color: #FFFFFF;
  font-family: Montserrat Regular;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.68px;
  width: 100%;
  text-align: center;
}

.aboutUsSectionDescription {
  white-space: pre-wrap;
  margin-bottom: 0;
  padding: 0;
  margin-top: 20px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.45px;
  line-height: 28px;
  text-align: center;
}

.aboutUsSectionSocialContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.infoBoxLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.aboutUsSocialLogo {
  height: 29px;
  width: 30px;
}

.aboutUsSocialName {
  margin-top: 5px;
  margin-left: 25px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 21px;
  letter-spacing: 0.45px;
  line-height: 28px;
}

.secondAboutUsSocialName {
  margin-top: 5px;
  margin-left: 25px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.45px;
  line-height: 28px;
}

.secondAboutUsSectionData {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 540px;
  margin: 0 50px;
}
.secondAboutUsSectionImage {
  border-radius: 100px;
  height: 150px;
  width: 150px;
}
.secondAboutUsSectionName {
  margin-top: 20px;
  color: #FFFFFF;
  font-family: Montserrat Regular;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.68px;
  line-height: 30px;
  width: 100%;
  text-align: center;
}
.secondAboutUsSectionRole {
  margin-top: 20px;
  color: #FFFFFF;
  font-family: Montserrat Regular;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.68px;
  line-height: 30px;
  width: 100%;
  text-align: center;
}
.secondAboutUsSectionDescription {
  white-space: pre-wrap;
  margin-bottom: 0;
  padding: 0;
  margin-top: 20px;
  color: #FFFFFF;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.45px;
  line-height: 14px;
  text-align: center;
}

.secondAboutUsSocialLogo {
  width: 22px;
}

a {
  text-decoration: none;
}

@media (max-width: 1200px) {

  .aboutUsSectionData {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 540px;
    margin: 0 20px 20px;
  }

  .secondAboutUsSectionData {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    min-height: 540px;
    margin: 0 20px;
  }

}

@media (max-width: 940px) {

  .aboutUsSectionDescription{
    font-size: 15px;
  }

}
